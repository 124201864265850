import React from 'react'


import Footer from './footer'
import '../styles/index.scss'


import layoutStyles from './layout.module.scss'

const Layout = (props) => {
     return (
        <div className={layoutStyles.container}>
          <div style={{ height: 10 }} />
          {props.image ? <div className={layoutStyles.content} style={{ backgroundImage: `url(${props.image})`, backgroundSize: 'contain', backgroundPositionX: 'center', backgroundRepeat: 'no-repeat' }}>{props.children}</div> : <div className={layoutStyles.content}>{props.children}</div>}
          <div style={{ height: 0 }} />
          <Footer imageDescription={props.footerImageDescription} />
          <div style={{ height: 0 }} />
        </div>
      )
}

export default Layout