import React from 'react'
import { Link, navigate } from 'gatsby'

import footerStyles from './footer.module.scss'

const pages = [
    '/work/surveillanceposter',
    '/work/thehobbitbookdesign',
    '/work/lincolnnationalparkbranding',
    '/work/styxcalligraphy',
    '/work/festivalofdangerousideasidentity',
    '/work/helveticabooklet',
    '/work/homeofficephotography',
    '/work/limitlessposter',
    '/work/abstractphotography',
    '/work/conspiracyposter'
]

const randomPage = () => {
    let randomIndex = Math.floor(Math.random() * pages.length);

    if (pages[randomIndex] == window.location.pathname) {
        randomIndex = (randomIndex + 1) % pages.length
    }

    navigate(pages[randomIndex])
}

const Footer = (props) => {
    return (
        <div style={{ padding: 16 }} className={footerStyles.footer}>
            <div><Link className={footerStyles.title} to="/">Riko Pinders</Link></div>
            <div style={{ maxWidth: 370 }}>{props.imageDescription}</div>
            <ul className={footerStyles.navList}>
                <li style={{ margin: 0 }}>
                    <a className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem} style={{ cursor: 'pointer' }} onClick={randomPage}>Random</a>
                </li>
                <li style={{ margin: '0 0 0 16px' }}>
                    <Link className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem} to="about">Info</Link>
                </li>
            </ul>
        </div>
    )
}

export default Footer